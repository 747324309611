<template>
    <div class="relative overflow-hidden w-full">
        <!-- Main Container with Horizontal Sliding -->
        <div
            class="flex transition-transform duration-500 ease-in-out w-full"
            :style="{ transform: `translateX(-${currentStep * 100}%)` }"
        >
            <!-- Step 1: Personal Info -->
            <div class="flex flex-col lg:flex-row w-full flex-shrink-0 lg:min-h-screen">
                <!-- Use auto height on mobile, min-h-screen only on larger screens -->

                <!-- Left Panel (Info) - Make it more compact on mobile -->
                <div class="w-full lg:w-1/2 bg-white p-4 lg:p-10 flex flex-col lg:items-center">
                    <div class="max-w-md mx-auto w-full">
                        <!-- Make content more compact on mobile -->
                        <div class="mb-4 lg:mb-8">
                            <h1 class="text-navy-blue text-3xl lg:text-4xl font-bold mb-2">
                                Try Space, on us.
                            </h1>
                            <h2 class="text-flushing-pink text-3xl lg:text-4xl font-bold mb-1">
                                Free one month trial
                            </h2>
                            <p class="text-gray-400 text-lg">
                                on your first box.
                            </p>
                        </div>

                        <!-- Box Image - smaller on mobile -->
                        <div class="mb-6 lg:mb-10">
                            <img
                                :src="require('static/assets/images/misc/spaceboxes.png')"
                                alt="Space Box"
                                class="w-48 h-48 lg:w-64 lg:h-64 object-contain mx-auto"
                            >
                        </div>

                        <!-- Service Perks - more compact on mobile -->
                        <div class="mb-6 lg:mb-10">
                            <h3 class="text-navy-blue text-xl font-bold mb-3 lg:mb-4">
                                Service Perks
                            </h3>
                            <ul class="text-navy-blue space-y-2 lg:space-y-3">
                                <li class="flex items-start">
                                    <span class="text-flushing-pink mr-2">✓</span>
                                    <span>Free supplies, pick-up and delivery</span>
                                </li>
                                <li class="flex items-start">
                                    <span class="text-flushing-pink mr-2">✓</span>
                                    <span>24x18x14" premium <span class="font-medium"> heavy-duty </span> box</span>

                                </li>
                                <li class="flex items-start">
                                    <span class="text-flushing-pink mr-2">✓</span>
                                    <span class="font-medium">Unlimited UPS Shipping</span>
                                </li>
                                <li class="flex items-start">
                                    <span class="text-flushing-pink mr-2">✓</span>
                                    <span>Secure UPS storage and handling</span>
                                </li>
                            </ul>
                        </div>

                        <!-- No Commitment -->
                        <div>
                            <p class="text-navy-blue font-medium">
                                No commitment. Cancel anytime. See our
                                <a
                                    href="https://spacereimagined.io/faq"
                                    class="text-flushing-pink"
                                    target="_blank"
                                >FAQ</a>
                                for details.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Right Panel (Form) -->
                <div class="w-full lg:w-1/2 bg-navy-blue p-4 lg:p-10 flex items-center flex-1">
                    <div class="max-w-md mx-auto w-full">
                        <!-- Logo centered -->
                        <div class="flex justify-center mb-10">
                            <img
                                class="h-[50px]"
                                :src="require('static/assets/images/brand/logo-white.svg')"
                                alt="Space Logo"
                            >
                        </div>

                        <!-- Form -->
                        <FastOrderForm
                            ref="personalInfoForm"
                            :quantity="quantity"
                            :applied-code="appliedCode"
                            @personal-info-validated="handlePersonalInfoValidated"
                            @validation-error="handleValidationError"
                        />

                        <div class="mt-5 text-center">
                            <p class="text-white text-sm">
                                Have a promo code? Enter it at checkout!
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Step 2: Shipping & Payment Information -->
            <div class="flex flex-col lg:flex-row w-full flex-shrink-0">
                <div class="w-full lg:w-1/2 bg-navy-blue p-6 lg:p-10">
                    <!-- Order Summary -->
                    <div class="max-w-md mx-auto">
                        <div
                            class="flex items-center text-white text-[16px] font-medium mb-8 cursor-pointer"
                            @click="previousStep"
                        >
                            <span class="mr-2">←</span>
                            <span>Back to Details</span>
                        </div>

                        <!-- Order Header -->
                        <h1 class="text-white text-2xl lg:text-3xl font-bold mb-4 lg:mb-6">
                            You've secured <br>your <span class="text-flushing-pink">free box</span>!
                        </h1>

                        <!-- Box Selection Section -->
                        <BoxSelectionSection
                            :monthly-rate="monthlyRate"
                            :model-value="quantity"
                            @update:quantity="updateQuantity"
                        />

                        <!-- Order Summary Section -->
                        <div class="bg-navy-blue-dark rounded-lg p-6">
                            <h3 class="text-white text-xl font-bold mb-4">
                                Order Summary
                            </h3>
                            <div class="flex justify-between mb-3">
                                <div class="text-white">
                                    Free Trial Box
                                </div>
                                <div class="text-white">
                                    Free
                                </div>
                            </div>
                            <div
                                v-if="quantity > 0"
                                class="flex justify-between mb-3"
                            >
                                <div class="text-white">
                                    Space Box x{{ quantity }}
                                </div>
                                <div class="text-white">
                                    ${{ (quantity * monthlyRate).toFixed(2) }}
                                </div>
                            </div>

                            <div class="border-t border-gray-700 my-5" />

                            <!-- Coupon and Referral Discounts -->
                            <OrderSummarySection
                                :quantity="quantity"
                                :monthly-rate="monthlyRate"
                                :coupon-details="appliedCode?.type === 'coupon' ? appliedCode.discount : null"
                                :minimum-months="minimumMonths"
                                :referral-credit="appliedCode?.type === 'referral' ? appliedCode.credit : 0"
                                :referral-applied="appliedCode?.type === 'referral'"
                            />

                            <div class="mt-3 mb-1">
                                <div class="flex justify-between text-white text-xl font-bold">
                                    <span>Total Due Today</span>
                                    <span>${{ finalTotal.toFixed(2) }}</span>
                                </div>
                                <div class="flex justify-between text-gray-400 text-sm mt-1">
                                    <span>Monthly Total</span>
                                    <span>${{ adjustedMonthlyTotal.toFixed(2) }}</span>
                                </div>
                            </div>

                            <div class="mt-5">
                                <button
                                    class="text-white underline text-sm hover:text-gray-300"
                                    @click="showCouponForm = !showCouponForm"
                                >
                                    Have a promo code?
                                </button>
                                <CouponSection
                                    :class="{ 'hidden': !showCouponForm }"
                                    :quantity="quantity"
                                    :initial-code="initialCouponCode"
                                    class="mt-4"
                                    :external-error="couponExternalError"
                                    @code-applied="applyCode"
                                    @code-removed="removeCode"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full lg:w-1/2 bg-white p-6 lg:p-10 flex items-center">
                    <div class="max-w-md mx-auto w-full">
                        <h2 class="text-2xl font-bold mb-6">
                            Shipping Details
                        </h2>
                        <ShippingForm
                            ref="shippingForm"
                            @update:shipping-data="updateShippingData"
                        />

                        <h2 class="text-2xl font-bold mt-10 mb-6">
                            Payment Details
                        </h2>
                        <SubscriptionPaymentForm
                            :fast-order-request-id="fastOrderRequestId"
                            :final-total="finalTotal"
                            :full-order-data="getFullOrderData()"
                            @payment-error="handlePaymentError"
                            @shipping-validation-error="handleShippingValidationError"
                            @promotion-error="handlePromotionError"
                        />

                        <p
                            v-if="orderError"
                            class="text-red-500 text-sm mt-2 text-center"
                        >
                            {{ orderError }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, computed, onMounted, watch, onBeforeMount } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import Form from 'root-js/utils/API/Form';
    import { defineAsyncComponent } from 'vue';
    import { mapGetters } from 'root-js/store/lib.js';
    import SubscriptionPaymentForm from '../components/payment/SubscriptionPaymentForm.vue';
    import { useGtag } from 'vue-gtag-next';

    // Lazy-loaded components
    const CouponSection = defineAsyncComponent(() => import('../components/fast-order/CouponSection.vue'));
    const FastOrderForm = defineAsyncComponent(() => import('../components/fast-order/FastOrderForm.vue'));
    const OrderSummarySection = defineAsyncComponent(() => import('../components/fast-order/OrderSummarySection.vue'));
    const ShippingForm = defineAsyncComponent(() => import('../components/fast-order/ShippingForm.vue'));
    const BoxSelectionSection = defineAsyncComponent(() => import('../components/fast-order/BoxSelectionSection.vue'));

    const router = useRouter();
    const { isAuthenticated } = mapGetters('auth');
    const route = useRoute();

    const quantity = ref(0);
    const monthlyRate = ref(15.00);
    const minimumMonths = 3;
    const currentStep = ref(0);
    const fastOrderRequestId = ref('');
    const orderError = ref('');
    const isSubmitting = ref(false);
    const personalInfo = ref(null);
    const shippingData = ref(null);
    const isShippingValid = ref(false);
    const appliedCode = ref(null);
    const showCouponForm = ref(false);
    const couponExternalError = ref('');
    const initialCouponCode = ref('');

    const personalInfoForm = ref(null);
    const shippingForm = ref(null);

    const monthlyTotal = computed(() => Number((quantity.value * monthlyRate.value).toFixed(2)));

    const totalBeforeDiscount = computed(() => Number((quantity.value * monthlyRate.value).toFixed(2)));

    const discountAmount = computed(() => {
        if (!appliedCode.value || appliedCode.value.type !== 'coupon') {
            return 0;
        }
        return Number(calculateDiscount(appliedCode.value.discount).toFixed(2));
    });

    const finalTotal = computed(() => {
        const totalBeforeDiscounts = totalBeforeDiscount.value;
        const couponDiscount = appliedCode.value?.type === 'coupon'
            ? calculateDiscount(appliedCode.value.discount)
            : 0;
        const referralDiscount = appliedCode.value?.type === 'referral'
            ? appliedCode.value.credit / 100
            : 0;
        const totalDiscounts = couponDiscount + referralDiscount;
        return Number(Math.max(0, totalBeforeDiscounts - totalDiscounts).toFixed(2));
    });

    const adjustedMonthlyTotal = computed(() => {
        let total = 15;

        if (quantity.value > 0) {
            total = (quantity.value + 1) * 15;
        }

        // Only apply permanent coupon discounts
        if (appliedCode.value?.type === 'coupon' && appliedCode.value.discount?.duration === 'forever') {
            if (appliedCode.value.discount.percent_off) {
                total *= 1 - appliedCode.value.discount.percent_off / 100;
            } else if (appliedCode.value.discount.amount_off) {
                total -= appliedCode.value.discount.amount_off / 100;
            }
        }

        return Number(total.toFixed(2));
    });

    const { event } = useGtag();

    onBeforeMount(async () => {
        if (isAuthenticated.value) {
            router.push('/your-space');
        }

        // Handle coupon codes from URL on page load
        const codeFromQuery = route.query.coupon || route.query.code || route.query.referral;
        if (codeFromQuery) {

            // Just set the initial code and show coupon form, but don't change steps
            showCouponForm.value = true;
            initialCouponCode.value = codeFromQuery;
        }
    });

    onMounted(() => {

        // Track add_to_cart event for the free trial box
        event('add_to_cart', {
            currency: 'USD',
            value: 0, // Free trial box
            items: [ {
                item_id: 'space_box_free_trial',
                item_name: 'Space Box Free Trial',
                price: 0,
                quantity: 1
            } ]
        });
    });

    const calculateDiscount = couponDetails => {
        if (!couponDetails) {
            return 0;
        }

        if (couponDetails.percent_off) {
            return totalBeforeDiscount.value * (couponDetails.percent_off / 100);
        }

        if (couponDetails.amount_off) {
            return couponDetails.amount_off / 100; // Convert cents to dollars
        }

        if (couponDetails.amount) {
            return couponDetails.amount / 100; // Don't exceed total
        }

        return 0;
    };

    const applyCode = codeData => {
        clearCouponErrors();

        if (!codeData) {
            appliedCode.value = null;
            return;
        }

        appliedCode.value = codeData;
    };

    const removeCode = () => {
        clearCouponErrors();
        appliedCode.value = null;
    };

    const previousStep = () => {
        if (currentStep.value > 0) {
            currentStep.value--;
        }
    };

    const updateQuantity = newQuantity => {
        const previousQuantity = quantity.value;
        quantity.value = newQuantity;

        // Track add_to_cart or remove_from_cart events based on quantity change
        if (newQuantity > previousQuantity) {

            // Track add_to_cart event
            event('add_to_cart', {
                currency: 'USD',
                value: monthlyRate.value,
                items: [ {
                    item_id: 'space_box',
                    item_name: 'Space Box',
                    price: monthlyRate.value,
                    quantity: 1
                } ]
            });
        } else if (newQuantity < previousQuantity) {

            // Track remove_from_cart event
            event('remove_from_cart', {
                currency: 'USD',
                value: monthlyRate.value,
                items: [ {
                    item_id: 'space_box',
                    item_name: 'Space Box',
                    price: monthlyRate.value,
                    quantity: 1
                } ]
            });
        }
    };

    const handlePersonalInfoValidated = info => {
        personalInfo.value = info;
        currentStep.value = 1;
    };

    const handleValidationError = error => {

    // Handle validation errors if needed
    };

    const updateShippingData = data => {
        shippingData.value = data;
        isShippingValid.value = data.isValid;
    };

    // Method to prepare full order data
    const getFullOrderData = () => {

        // Personal information
        const personalData = personalInfo.value && personalInfo.value
            ? {
                first_name: { value: personalInfo.value.first_name || '' },
                last_name: { value: personalInfo.value.last_name || '' },
                email: { value: personalInfo.value.email || '' },
                phone: { value: personalInfo.value.phone || '' },
                country_code: { value: personalInfo.value.country_code || 'US' }
            }
            : {
                first_name: { value: '' },
                last_name: { value: '' },
                email: { value: '' },
                phone: { value: '' },
                country_code: { value: 'US' }
            };

        // Shipping information
        const shippingDataObj = shippingData.value && shippingData.value
            ? {
                address_line_1: { value: shippingData.value.address_line_1 || '' },
                address_line_2: { value: shippingData.value.address_line_2 || '' },
                state: { value: shippingData.value.state || '' },
                city: { value: shippingData.value.city || '' },
                country: { value: shippingData.value.country || 'US' },
                postal_code: { value: shippingData.value.postal_code || '' }
            }
            : {
                address_line_1: { value: '' },
                address_line_2: { value: '' },
                state: { value: '' },
                city: { value: '' },
                country: { value: 'US' },
                postal_code: { value: '' }
            };

        // Promotion and referral data
        const promotionData = {
            quantity: { value: quantity.value + 1 },
            promotion_code_id: {
                value: appliedCode.value && appliedCode.value && appliedCode.value.type === 'coupon'
                    ? appliedCode.value.id
                    : ''
            },
            referrer_id: {
                value: appliedCode.value && appliedCode.value && appliedCode.value.type === 'referral'
                    ? appliedCode.value.referrerId
                    : ''
            },
            rewardful_referral_id: { value: '' },
            credit_balance_promotion_code_id: { value: '' }
        };

        // Combine all data
        return {
            ...personalData,
            ...shippingDataObj,
            ...promotionData
        };
    };

    // Method to handle shipping validation errors from payment step
    const handleShippingValidationError = errors => {

        // Forward errors to the shipping form component
        if (shippingForm.value) {
            shippingForm.value.setErrors(errors);
        }

        // Set focus back to shipping section
        currentStep.value = 1; // Step 2 (0-indexed)
        orderError.value = 'Please check your shipping information';
    };

    const submitFullOrder = async () => {
        if (!personalInfo.value || !shippingData.value || !shippingData.value.isValid) {
            return;
        }

        isSubmitting.value = true;
        orderError.value = '';

        try {

            /*
             * With the new API approach, we no longer need to create a draft order
             * The full order will be created during checkout using the open-fast-order endpoint
             */

            // GA Begin Checkout Event
            event('begin_checkout', {
                currency: 'USD',
                value: finalTotal.value,
                items: [ {
                    item_id: 'space_box',
                    item_name: 'Space Box',
                    quantity: quantity.value,
                    price: monthlyRate.value
                } ]
            });


        } catch (error) {
            console.error('Order preparation failed:', error);
            orderError.value = error.message || 'Failed to prepare order. Please try again.';
        } finally {
            isSubmitting.value = false;
        }
    };

    const handlePaymentError = error => {

        // Only set orderError if it's a different message than what's shown in payment form
        if (error && error.message) {

        /*
         * No need to duplicate the error message - it's already shown in the payment form
         * orderError.value = error.message;
         */
        }
    };

    const handlePromotionError = errors => {

        // Remove the applied code since it's causing errors
        appliedCode.value = null;

        // Show the coupon form so the user can try a different code
        showCouponForm.value = true;

        // Set error messages
        const errorMessage = errors.promotion_code_id?.[0]
            || errors.referrer_id?.[0]
            || errors.rewardful_referral_id?.[0]
            || errors.credit_balance_promotion_code_id?.[0]
            || 'There was an issue with your coupon or referral code.';

        couponExternalError.value = errorMessage;
        orderError.value = 'Please check your promotion code and try again.';
    };

    // Add a function to clear coupon errors
    const clearCouponErrors = () => {
        couponExternalError.value = '';
    };
</script>

<style scoped>
.bg-navy-blue {
    background-color: #000033;
}

.bg-navy-blue-light {
    background-color: #000044;
}

.bg-navy-blue-dark {
    background-color: #1a1a47;
}

.bg-flushing-pink {
    background-color: #FF1493;
}

/* Enhanced transition for smooth sliding */
.transition-transform {
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Mobile optimizations */
@media (max-width: 768px) {

    /* Make content more compact on mobile */
    .max-w-md {
        padding: 0;
    }

    /* Adjust form spacing */
    form {
        margin-bottom: 2rem;
    }

    /* Remove extra space at bottom */
    .min-h-screen {
        min-height: auto;
    }

    /* Ensure proper spacing around sections */
    .p-6 {
        padding: 1rem;
    }

    /* Reduce image sizes */
    img.object-contain {
        max-height: 200px;
    }
}
</style>
