<template>
    <div
        class="absolute tooltip"
        :class="[
            position === 'top' && '-top-8 left-1/2 transform -translate-x-1/2',
            position === 'bottom' && 'top-full left-1/2 transform -translate-x-1/2 mt-2',
            position === 'left' && 'right-full top-1/2 transform -translate-y-1/2 -ml-2',
            position === 'right' && 'left-full top-1/2 transform -translate-y-1/2 ml-2'
        ]"
    >
        <div
            class="
                bg-navy
                text-white
                text-[10px]
                py-1
                px-2
                rounded-[4px]
                whitespace-nowrap
                shadow-sm
            "
        >
            {{ text }}
        </div>
        <div
            class="
                absolute
                w-2
                h-2
                bg-navy
                transform
                rotate-45
            "
            :class="[
                position === 'top' && 'bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
                position === 'bottom' && '-top-1 left-1/2 -translate-x-1/2',
                position === 'left' && 'right-0 top-1/2 -translate-y-1/2 translate-x-1/2',
                position === 'right' && '-left-1 top-1/2 -translate-y-1/2'
            ]"
        />
    </div>
</template>

<script setup>
    defineProps({
        text: {
            type: String,
            required: true
        },
        position: {
            type: String,
            default: 'top',
            validator: value => ['top', 'bottom', 'left', 'right'].includes(value)
        }
    });
</script>

<style scoped>
.tooltip {
    z-index: 50;
    pointer-events: none;
}
</style>
